<template>
  <div class="preview-layout">
    <Header :google-play-store-link="bkashAppGooglePlayStoreLink"
            :apple-store-link="bkashAppAppleStoreLink"
            :merchant-logo="pageInfo.logo"
            @homeClick="goToHome"/>
    <router-view></router-view>
    <Footer :terms-and-condition-link="termsAndConditionLink"
            :merchant-registration-link="merchantRegistrationLink"
    />
  </div>
</template>

<script>
import Header from 'cpp-js-util/components/common/NavBar'
import Footer from "cpp-js-util/components/common/Footer";

export default {
  name: "PreviewLayout",
  components: {
    Header,
    Footer
  },
  computed: {
    pageInfo() {
      return this.$store.getters.getPageInfo;
    },
    bkashAppGooglePlayStoreLink() {
      return process.env.VUE_APP_BKASH_APP_PLAY_STORE_URL
    },
    bkashAppAppleStoreLink() {
      return process.env.VUE_APP_BKASH_APP_APPLE_STORE_URL
    },
    termsAndConditionLink() {
      return process.env.VUE_APP_TERMS_AND_CONDITION_URL
    },
    merchantRegistrationLink() {
      return process.env.VUE_APP_MERCHANT_REGISTRATION_URL
    }
  },
  methods: {
    goToHome() {
      this.$router.push({name: 'customer-home-page'})
    }
  }
}
</script>

<style scoped>
.preview-layout {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
