<template>
  <div class="footer ignore-in-print">
    <div class="cpp-container">
      <div class="bkash-text">
        © {{ currentYear }} bKash, a BRAC Bank Company. All rights reserved
      </div>
      <div class="footer-links">
        <a class="link" target="_blank" :href="termsAndConditionLink">Terms and Conditions</a>
        <a class="link" target="_blank" :href="merchantRegistrationLink">Be a Merchant</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    termsAndConditionLink: String,
    merchantRegistrationLink: String,
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>

<style scoped>
.footer {
  padding: 5px 35px;
  display: flex;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  min-height: 64px;
  background-image: linear-gradient(to bottom, #fbfbfb, #ededed 50%, #f1f1f1);
}

.cpp-container {
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bkash-text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #464646;
}

.footer-links .link {
  color: #e2136e;
  text-decoration: none;
}

.footer-links .link:not(:last-child):after {
  content: '|';
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .cpp-container {
    flex-direction: column;
    justify-content: center;
  }

  .bkash-text {
    font-size: 15px;
  }

  .footer-links {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .footer {
    padding-left: 5px;
    padding-right: 5px;
  }

  .bkash-text {
    font-size: 12px;
    letter-spacing: normal;
    margin-bottom: 3px;
  }

  .footer-links {
    font-size: 12px;
  }
}
</style>

